<template>
  <div class="page">
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          @click="toRouteTitle(item)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item-l">
            <div class="title">
              {{ item.topicTitle || "" }}
            </div>
            <div class="bottom">
              <div class="time">{{ item.createTime }}</div>
              <div class="sum">
                <img src="./img/lookIcon.png" alt="" />
                <div>{{ item.readCount }}</div>
              </div>
            </div>
          </div>
          <div class="item-r">
            <img :src="item.topicPicture" />
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getTopicListURL } from "./api.js";
import wx from "weixin-js-sdk";
export default {
  name: "styleExhibition",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    communityId() {
      return this.$store.state.communityId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  methods: {
    // 点击跳转话题详情页
    toRouteTitle(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
        });
      }
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
        sortField: "NEW",
        topicType: "26",
      };
      this.$axios.get(`${getTopicListURL}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            this.listData.push(item);
          });
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  .list {
    .item {
      display: flex;
      width: 100%;
      height: 188px;
      padding: 16px 0 20px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        flex: 1;
        position: relative;
        .title {
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
          line-height: 44px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .bottom {
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 24px;
          color: #b4b4b4;
          position: absolute;
          bottom: 0;
          .time {
            white-space: nowrap;
          }
          .sum {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              margin-right: 2px;
              width: 26px;
            }
          }
        }
      }
      .item-r {
        width: 226px;
        height: 100%;
        img {
          border-radius: 12px;
          width: 226px;
          height: 150px;
        }
      }
    }
  }
}
</style>